<template>
    <div class="tab3" style="height: 100vh">
        <ul class="handle_list">
            <li @click="toLink(item.routeName, item.options)" v-for="(item, index) in menuList" :key="index">
                <img :src="item.icon" alt="" />
                <p>{{ item.menuName }}</p>
            </li>
        </ul>
    </div>
</template>
<script>
import API from "../../common/api/api"
import { Dialog, Toast } from "vant"
import { toLink } from "../../common/utils/router"
export default {
    name: "BusinessHandle",
    data() {
        return {
            menuList: [
                {
                    menuName: "订单查询",
                    icon: require("../../assets/images/ddcx.png"),
                    routeName: "orderQuery",
                    options: {},
                },
                {
                    menuName: "来电提醒",
                    icon: require("../../assets/images/ywbl1.png"),
                    routeName: "PackageOpen",
                    options: { type: 1 },
                },
                {
                    menuName: "来电显示",
                    icon: require("../../assets/images/ywbl2.png"),
                    routeName: "PackageOpen",
                    options: { type: 2 },
                },
                {
                    menuName: "实名认证",
                    icon: require("../../assets/images/ywbl4.png"),
                    routeName: "realName",
                    options: {},
                },

                {
                    menuName: "销户申请",
                    icon: require("../../assets/images/ywbl5.png"),
                    routeName: "cancel",
                    options: {},
                },
                {
                    menuName: "补换卡",
                    icon: require("../../assets/images/ywbl7.png"),
                    routeName: "changeCard",
                    options: {},
                },
                {
                    menuName: "开发票",
                    icon: require("../../assets/images/ywbl3.png"),
                    routeName: "invoiceVerify",
                    options: {},
                },
                {
                    menuName: "变更套餐",
                    icon: require("../../assets/images/geng2.png"),
                    routeName: "verification", //verification
                    options: {},
                },
                {
                    menuName: "短信免打扰",
                    icon: require("../../assets/images/dxsrfh.png"),
                    routeName: "WillRegister", //verification
                    options: {},
                },
                {
                    menuName: "过户发起",
                    icon: require("../../assets/images/ywbl6.png"),
                    routeName: "transAcctS",
                    options: {},
                },
                {
                    menuName: "过户接收",
                    icon: require("../../assets/images/ywbl8.png"),
                    routeName: "transAcctT",
                    options: {},
                },
                // {
                //     menuName: "订购呼转",
                //     icon: require("../../assets/images/geng2.png"),
                //     routeName: "callDivert",
                //     options: {},
                // },
                // {
                //     menuName: "订购权益",
                //     icon: require("../../assets/images/geng2.png"),
                //     routeName: "Appreciation",
                //     options: {},
                // },
            ],
        }
    },
    watch: {},
    created() { },
    components: {},
    methods: {
        toLink,
    },
}
</script>
<style lang="scss" scoped>
// 业务办理
.handle_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: px2rem(40);
}

.handle_list li {
    width: 50%;
    height: px2rem(120);
    margin-top: px2rem(20);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.handle_list li img {
    width: px2rem(50);
    height: px2rem(50);
}

.handle_list li p {
    font-size: px2rem(26);
    color: #333;
}
</style>